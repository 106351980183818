import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Massage() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Kropsterapi</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Kropsterapi.png'} loading="lazy" alt="Dette billede ser man en patient få massage" className="underside_img"/>
                        </div>

                    <div className="underside_text">
                        <p className="underside_p">Kropsterapi er til dig, som ønsker at skabe balance i din krop og
slippe af med dine smerter og ubehag, uden at lægge dig fast på en
bestemt behandlingsform.</p>
                        <p className="underside_p">Ved Gestus Behandling er jeg dedikeret til at hjælpe dig, formindske
dine smerter, øge dit velvære samt hjælpe din krop tilbage i trivsel.</p>
                        <p className="underside_p">En kropsterapi ved Gestus Behandling er en helhedsorienteret
tilgang, der tager hensyn til såvel din fysiske, som din mentale
tilstand.</p>
                        <p className="underside_p">Jeg har en bred viden inden for forskellige behandlingsmetoder og
teknikker, herunder fysiurgisk massage, kranio sakral terapi,
triggerpunkter, øreakupunktur, organmassage, meridianmassage mm.
Derudover har jeg en specialviden om hovedpine, nakke, skulder,
korsben, overgangsalderen, facetsledsproblematikker mm, der giver mig mulighed for at identificere netop dit specifikke område af
ubalance, og behandle det med en skræddersyet behandling netop til
dig.</p>
                    </div>
                </section>
            <Footer/>

        </section>
    )
}

export default Massage
