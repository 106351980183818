import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Babybehandling() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Baby</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/baby.jpg'} loading="lazy" alt="Dette billede ser man en baby som for en behandling" className="underside_img"/>
                        </div>
                    <div className="underside_text">
                        <p className="underside_p">Ønsker du at give din baby de bedste betingelser for en god trivsel uden unødige spændinger eller skævheder? Så skal du booke tid til et babytjek hvor jeg blidt og nænsomt gennemgår din babys nakke, bækken og ryg for spændinger. Møder jeg spændinger under undersøgelsen, afspænder jeg dem blidt med nænsomme kranio sakral teknikker.</p>
                        <p className="underside_p">Spændingerne kan sidde både i nakken, bækkenet og ryggen, men også spændinger i kæben, kan give udfordringer for den lille baby i forbindelse med amning/flaske samt sprogudvikling.</p>
                        <p className="underside_p">Ubehandlede spændinger kan betyde, at dit barn ikke trives, græder meget, eller ikke er så aktivt som man kunne ønske sig.</p>
                        <p className="underside_p">Mange henvender sig også til mig, blot for at få et babytjek, og være sikre på, at deres baby har de bedste betingelser i både muskulaturen, nervesystemet og bevægeapparatet for at udvikle sig bedst muligt og få den bedste start på livet.</p>
                        <p className="underside_p">I Gestus Behandling, har jeg særlig god erfaring med behandling af:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Spændinger i muskulaturen og nervesystemet</li>
                            <li className="underside_li">Favoritside</li>
                            <li className="underside_li">Bagsidedominans</li>
                            <li className="underside_li">Mavekneb</li>
                            <li className="underside_li">Søvnproblematikker</li>
                            <li className="underside_li">Spændinger i kæben</li>
                        </ul>
                        <p className="underside_p">Babytjek og behandling anbefales inden for de første 12 uger, men kan også sagtens gavne større babyer eller tumlinge.</p>
                        <p className="underside_p">Til info tilbyder jeg også gravidmassage, babymassage og efterfødselsmassage, ligesom mødregruppe arrangementer hvor I ellers jeres børn på skift behandles, kan arrangeres. Kontakt mig på 2643 6760 for denne behandling.</p>
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default Babybehandling