import React from 'react'

function OmGEstusComponet() {
    return (

        <section className="undersider">
        <h2 className="undersider_h2">Om Gestus Behandling</h2>
            <div className="underside_images">
            <img src={process.env.PUBLIC_URL + '/Img/Protraet.jpg'} loading="lazy" alt="Protraet af Pia Sejer Lind" className="ProtraetImg"/>
            </div>

        <div className="underside_text">
            <p className="underside_p">Jeg hedder Pia Sejer Lind, jeg er uddannet lægeeksamineret fysiurgisk massør, kropsterapeut, Kranio Sakral Terapi behandler, babybehandler, coach og mentor og derudover er jeg indehaver af Gestus Behandling.</p>

            <p className="underside_p">Når jeg behandler dig, er det vigtigt for mig, altid at have en anerkendende, tillidsfuld og afsøgende tilgang, så du føler dig tryg og imødekommet i det øjeblik du kommer ind ad min dør.</p>

            <p className="underside_p">Det er vigtigt for mig, at du altid får en professionel og korrekt behandling, og jeg starter derfor altid med en samtale, om hvad du ønsker jeg skal gøre for dig, og hvordan du ønsker jeg hjælper dig bedst muligt. Sammen planlægger vi det videre forløb, og beslutter hvilken behandlingsform, som giver bedst mening for dig og dine smerter - Altså, en god Gestus fra starten af.</p>

            <p className="underside_p">Som en bonus kan det nævnes at Gestus Behandling er RAB-godkendt, hvilket er dit kvalitetsstempel for en høj faglig uddannelse samt medlemskab af godkendt brancheforening. Flere forsikringsselskaber yder desuden tilskud til et forløb ved en behandler, som er RAB-godkendt. Tjek dette med dit forsikringsselskab eller ring til Gestus Behandling for at høre yderligere.</p>
        </div>
    </section>



    )
}

export default OmGEstusComponet

