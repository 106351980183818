import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function KranioSakralBehandling() {
    return (
        <section>
            <Header/>
                <section className="undersiderkst">
                    <h2 className="undersider_h2">Kranio Sakral behandling</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/KSTBehandling.jpg'} loading="lazy" alt="Dette billede ser man en patient få kranio sakral behandling" className="underside_img"/>
                        </div>

                    <div className="underside_kst">
                        <b className="underside_p">Erfaringer viser at Kranio Sakral Terapi kan have en positiv
                        effekt på lidelser som:</b>
                        <ul className="underside_list">
                            <li className="underside_li">Hovedpine og migræne</li>
                            <li className="underside_li">Piskesmæld og hjernerystelse (også af ældre dato)</li>
                            <li className="underside_li">Stress symptomer</li>
                            <li className="underside_li">Muskelspændinger</li>
                            <li className="underside_li">Ryg- og nakkesmerter</li>
                            <li className="underside_li">Bækkensmerter</li>
                            <li className="underside_li">Angst</li>
                            <li className="underside_li">Søvnbesvær</li>
                            <li className="underside_li">Uro i kroppen</li>
                            <li className="underside_li">Choktilstand</li>
                            <li className="underside_li">Kæbespænding</li>
                        </ul>
                    </div>

                    <div className="underside_text">
                        <p className="underside_p">Du kan vælge at få Kranio Sakral som en selvstændig behandling med
fokus på fx afstresning. Eller du kan med fordel kombinere
behandlingen med en fysiurgisk massage, således du får en
behandling, som kommer helt til bunds i dine problemstillinger og

eventuelle smerter. En kombinationsbehandling vil typisk vare 60-90
minutter.</p>
                        <h3 className="underside_h3">Behandlingen</h3>
                        <p className="underside_p">En Kranio Sakral behandling ved Gestus Behandling indledes altid
med en samtale om hvem du er og hvorfor du er kommet. En
behandling varer typisk 60 minutter, hvor du ligger afslappet og fuldt
påklædt på en briks, mens jeg med nænsomme berøringer løsner op
for spændinger og blokeringer, (især omkring hoved, rygsøjle og
bækken). Du vil ofte opleve en dyb afspænding - en nærmest
meditativ tilstand.</p>
                        <p className="underside_p">Enkelte gange er én Kranio Sakral terapeutisk behandling nok, men
når der er tale om længerevarende og kroniske problemstillinger, må
der påregnes flere behandlinger og bliver vi enige om det, planlægger
vi sammen dit videre forløb. Du kan dog oftest forvente at mærke
positive forandringer inden for de første 3 behandlinger.</p>
                    </div>    
                </section>
            <Footer/>
        </section>
    )
}

export default KranioSakralBehandling
