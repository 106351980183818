import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function SaerligeBehov() {
    return (
        <section>
        <Header/>
            <section className="saerligebehov">
                <h2 className="saerligebehov_h2">Særlige behov eller andre hensyn</h2>
                    <div className="saerligebehov_images">
                        <img src={process.env.PUBLIC_URL + '/Img/SÆRLIGEBEHOV2.png'} loading="lazy" alt="Dette billede viser en figur af en rygsøjle og en figur af et skelet" className="saerligebehov_img"/>
                    </div>

                <div className="saerligebehov_text">
                    <p className="saerligebehov_p">I Kranio Sakral Terapien arbejder jeg med et meget blidt – næsten ikke mærkbart – tryk, som dog er særdeles præcist og effektivt. Denne blide behandling gør det muligt at jeg kan behandle særligt sensitive personer, som normalt ikke kan lide berøring.</p>
                    <p className="saerligebehov_p">Jeg har gennem min uddannelse og mit arbejde stor erfaring med denne gruppe mennesker. - Både særlige sensitive, mennesker med udviklingshæmning eller andre med særlige behov. Du skal derfor ikke holde dig tilbage med at kontakte mig hvis du har nogle særlige hensyn, som skal tages, for at behandlingen passer lige præcis til dig.</p>
                    <p className="saerligebehov_p">Derudover er jeg specialuddannet indenfor behandling af børn med diagnoser, herunder bl.a. ADHD, autisme, angst mm hvor jeg arbejder med at skabe ro og balance i barnets krop og nervesystem gennem afspænding og blide kranio sakrale teknikker.</p>
                    <p className="saerligebehov_p">Inden denne behandling, afholder jeg altid en telefonisk samtale med en af forældrene, hvor vi taler om mulighederne for at hjælpe barnet bedst muligt. Ring på 2643 6760 for at høre mere om mulighederne for et forløb.</p>
                </div>
            </section>
        <Footer/>
    </section>
    )
}

export default SaerligeBehov